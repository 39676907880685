// import $ from 'jquery';

// $(() => {
//     setTimeout(() => {
//         alert('jQuery triggered via js.js')
//     }, 2500);
// });

$(function () {

    /*
    $(window).on('resize', function () {
        if ($('.js-short-text-company').length) {
            short_text_company();
        }
    });
    */

    // Определение айфонов и айпадов для фикса паралакс-эффекта
    $('body').each(function () {
        // if ( (navigator.platform.indexOf("iPhone") != -1) || (navigator.platform.indexOf("iPad") != -1) || (navigator.platform.indexOf("iPod") != -1) ) {
        // if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        // if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
        if (navigator.userAgent.match(/(iPod|iPhone|iPad|_Safari|_safari)/)) {
            $(this).addClass('ios-flag');
        }
    });

    // Initialize Select2 Elements / селект выбора компании в PopUp формы нового отзыва, с AJAX загрузкой компаний при вводе
    /*
    $('.select2-ajax-modal-company').select2({
        dropdownParent: $('#modal-form-company_review'),
        placeholder: '...',
        tags: true,
        minimumInputLength: 1,
        language: 'ru',
        ajax: {
            url: '/form/ajax-select-options-company-review',
            dataType: 'json',
            type: "POST",
            delay: 50,
            processResults: function (data, params) {
                params.page = params.page || 1;
                return {
                    results: data.results
                    // ,pagination: {more: (params.page * 2) < data.total_count}
                };
            },
            cache: false
        }
    }).on('select2:opening', function (e) {
        $(this).data('select2').$dropdown.find(':input.select2-search__field').attr('placeholder', 'Поиск существующей компании или добавление новой ...')
    });
    */

    // инфо-сообщения
    $('.toastrDefaultSuccess').each(function () {
        toastr.success($(this).text());
    });
    $('.toastrDefaultInfo').each(function () {
        // toastr.options.timeOut = 5000;
        // toastr.options.progressBar = true;
        toastr.info($(this).text());
    });
    $('.toastrDefaultWarning').each(function () {
        toastr.warning($(this).text());
    });
    $('.toastrDefaultError').each(function () {
        toastr.error($(this).text());
    });

    // меню администратора
    // $('#navbarDropdown').click(function(){ //устарел
    $('#auth #navbarDropdown').on('click', function () {
        $('#auth').addClass('open');
        $('#auth .dropdown-menu').slideToggle();
        $('#auth .dropdown-menu').css("position", "absolute");
        $('#auth .dropdown-menu').css("top", "40px");
        $('#auth .dropdown-menu').css("right", "0");
    });
    $(document).on('mouseup', function (e) { // событие клика по веб-документу
        if ($('#auth.open').length) {
            var div = $('#auth.open');
            if (!div.is(e.target) // если клик был не по блоку
                && div.has(e.target).length === 0) { // и не по его дочерним элементам
                $('#auth.open .dropdown-menu').hide();
                $('#auth.open').removeClass('open');
            }
        }
    });
    $(document).on('keyup', function (e) {
        if ($('#auth.open').length) {
            if (e.key == "Escape") {
                $('#auth.open .dropdown-menu').hide();
                $('#auth.open').removeClass('open');
            }
        }
    });

    // отступ телефона в шапке от края
    /*
    if ($('.b-round').length) {
        var b_header = $('.b-round');
        var pl = parseInt(b_header.css('padding-right')) + 20;
        $('header .site-info').addClass('js-info--shift-for-b-round-header');
        $('header .site-info').css('right', pl + 'px');
    }
    */

    // одиночные изображения в статьях - обнуление padding родительского <p>
    $('.article-page-content .b-article-content p').each(function () {
        if ($(this).find('img:only-of-type').length) {
            // img - единственный элемент внутри <p> (учитываются только теги, текст внутри не учитывается)
            if ($(this).text().length == 0) {
                // текст внутри <p> отсутствует
                // $(this).css('padding-left', '0');
                // $(this).css('padding-right', '0');
                $(this).css('padding', '20px 0');
                $(this).css('max-width', 'unset');
                $(this).css('text-align', 'center');
            }
        }
        // if ($(this).find('img:only-child').length) { /* аналогично */ }
    });

    // "раскрыть" для длинных текстов отзывов
    /*
    $('.js-short-text').each(function () {
        let h = $(this).data('short-text-height');
        if ($(this).height() > h) {
            $(this).addClass('text-is-short').css('overflow', 'hidden').height(h);
            $(this).after('<div class="short-text-more">Раскрыть...</div>');
        }
    });

    // "раскрыть" для длинного блока описания в карточке компании
    if ($('.js-short-text-company').length) {
        short_text_company();
    }
    function short_text_company() {
        $('.js-short-text-company').each(function () {
            // let w = $('html').width();
            // if (w < 992) {
            let h = $('.b-company .left').height();
            if ($(this).height() > h + 30) {
                $(this).addClass('text-is-short').css('overflow', 'hidden').height(h);
                $(this).after('<div class="short-text-more">Раскрыть...</div>');
            } else {
                $(this).removeClass('text-is-short').css('height', 'unset');
                $(this).parent().find('.short-text-more').remove();
                if ($(this).height() > h + 30) {
                    $(this).addClass('text-is-short').css('overflow', 'hidden').height(h);
                    $(this).after('<div class="short-text-more">Раскрыть...</div>');
                }
            }
            // }
        });
    };
    $(document).on('click', '.short-text-more', function () {
        $(this).parent().find('.js-short-text').removeClass('text-is-short').css('height', '100%');
        $(this).parent().find('.js-short-text-company').removeClass('text-is-short').css('height', '100%');
        $(this).remove();
    });
    */
    // END "раскрыть" для длинных текстов отзывов

    // активные ссылки ~
    $('.nav-link').each(function () {
        var loc = window.location.protocol + '//' + window.location.host + window.location.pathname
        var link = this.href
        if (link == loc) {
            $(this).addClass('active')
            // if ($(this).parents().find('.col-right-tags').hasClass('article')) {
            //     $(".header .nav-link[href^='/article']").addClass('active')
            //     $('.page-type .article').addClass('active')
            // }
            // if ($(this).parents().find('.col-right-tags').hasClass('news')) {
            //     $('.page-type .news').addClass('active')
            // }
        }
        if (window.location.pathname.includes('/article/')) {
            $(".header .nav-link[href^='/article']").addClass('active')
        }
    });

    // Parallax

    $(window).on('resize', function () {
        correct_height_parallax_block();
    });
    if ($('.b-parallax').length) {
        correct_height_parallax_block();
    }

    function correct_height_parallax_block() {
        $('.b-parallax').each(function () {
            let parallax_block = $(this);
            let img_scroll = parallax_block.find('.img-scroll');
            let img_block = parallax_block.find('.img-container');
            if (img_block.height() > parallax_block.height()) {
                img_scroll.height(img_block.height());
                parallax_block.height(img_block.height());
            }
        });
    }

    $(window).on('scroll', function () {
        let target_class = '.img-scroll';
        if ($('.b-parallax').length && $('.img-scroll').length) {
            if (!$('.ios-flag_').length) {
                if (is_shown(target_class)) {
                    scroll_el(target_class);
                }
            }
        }
    });

    // Проверка, находится ли элемент в области просмотра
    var original_top = null;
    function is_shown(target) {
        var wt = $(window).scrollTop(); //координаты верхней части окна
        var wh = $(window).height(); //высота окна
        var eh = $(target).outerHeight(); //высота изображения
        var et = $(target).offset().top; //координаты верхней границы изображения
        if (original_top === null) {
            original_top = et;
        }
        if (wt + wh >= et && wt + wh - eh * 2 <= et + (wh - eh)) {
            return true;
        } else {
            return false;
        }
    }

    // Parallax эффект
    function scroll_el(target) {
        var wt = $(window).scrollTop(); //координаты верхней части окна
        var wh = $(window).height(); //высота окна
        var eh = $(target).outerHeight(); //высота изображения
        var et = $(target).offset().top; //координаты верхней границы изображения
        var so = (wt - et) / eh; //относительное смещение [-1..1]
        var sk = so * 200; //усилитель коэффициента смещения [-20..20]
        var sa = original_top + sk; //новые координаты верхней границы изображения
        $(target).offset({ top: sa });
        // console.log(sk);
    }

    // END Parallax

    // Слайдер
    if ($('.slider').length) {
        var slider = tns({
            container: '.slider',
            items: 1,
            slideBy: 'page',
            mouseDrag: true,
            loop: true,
            autoHeight: false,
            // autoWidth: true,
            // arrowKeys: false,
            swipeAngle: false,
            speed: 2000,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            // autoplayButton: '.autoplayButton',
            // add to view: <button type="button" class="autoplayButton"></button >
            autoplayButtonOutput : false,
            controls: false,
            // controlsPosition: 'bottom',
            navPosition: 'bottom',
            autoplayPosition: 'bottom',
        });
    }

    // Прокрутить страницу отзывов при переходе на n+1
    if ($('.reviews-page').length) {
        var url_string = window.location.href.toString();
        var url = new URL(url_string);
        var page = url.searchParams.get("page");
        if (page !== null && page != 1) {
            var reviews_block = document.querySelector('.b-reviews-content');
            if (reviews_block !== null) {
                reviews_block.scrollIntoView({
                    behavior: 'smooth'
                    // behavior: 'instant'
                });
            }
        }
    }

});

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

lightbox.option({
    // 'wrapAround': true,
    'imageFadeDuration': 200,
    'resizeDuration': 200,
    'positionFromTop': 10,
    'albumLabel': "%1 из %2",
    'showImageNumberLabel': false
});

/*

jQuery(document).ready(function($) {});
//метод устарел, замена:
jQuery(function() {});
$(function() {});

---

console.log('jQuery_02');
$('.title').addClass('js_test_01');
$('#navbarDropdown').css("float", "left");

*/

